<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="dataMeta.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dataMeta.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button variant="primary" @click="addNew()">
                <span class="text-nowrap">Add Component</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        id="overlay-background"
        spinner-variant="primary"
        :show="loadTable"
        variant="transparent"
        opacity="1.00"
        blur="0.5em"
        rounded="sm"
      >
        <b-table
          ref="refListTable"
          :busy.sync="loadTable"
          class="position-relative"
          :items="fetchData"
          responsive
          :fields="tableColumns"
          primary-key="id_customer"
          :sort-by.sync="dataMeta.sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="dataMeta.isSortDirDesc"
          :bordered="true"
          :hover="true"
        >
          <template #cell(component_name)="data">
            <span>{{ data.item.ref_component.name }}</span>
          </template>
          <template #cell(order)="data">
            <span>{{ data.item.order_number }}</span>
          </template>
          <template #cell(status)="data">
            <b-badge :variant="data.item.is_active === 1 ? 'success' : 'danger'">
              {{ data.item.is_active === 1 ? 'Aktif' : 'Tidak Aktif' }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`branch-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
                @click="navToComponentCreate(data.item)"
              />
              <b-dropdown
                variant="link"
                toggle-class="p-0"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>
                <!-- <b-dropdown-item>
              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Download</span>
            </b-dropdown-item> -->
                <b-dropdown-item @click="setActive(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{
                    data.item.is_active === 0 ? 'Set Aktif' : 'Set Not Aktif'
                  }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteComponent(data.item)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="dataMeta.currentPage"
              :total-rows="dataMeta.total"
              :per-page="dataMeta.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <add-new
      :is-add-new-page-sidebar-active.sync="isAddNewPageSidebarActive"
      :data="componentData"
      :type-form="typeForm"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
  BBadge,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import store from '@/store'
import usePageModule from '../../usePageModule'
import AddNew from './Create.vue'
import useComponent from './useComponent'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect,
    BBadge,
    AddNew,
    BDropdown,
    BDropdownItem,
  },
  setup(props, context) {
    const PAGE_STORE_MODULE_NAME = 'cms-pages'

    // Register module
    if (!store.hasModule(PAGE_STORE_MODULE_NAME)) {
      store.registerModule(PAGE_STORE_MODULE_NAME, usePageModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAGE_STORE_MODULE_NAME)) {
        store.unregisterModule(PAGE_STORE_MODULE_NAME)
      }
    })
    const {
      // Sidebar
      loadTable,

      // Filter
      pageId,
      componentData,

      // meta pagination
      dataMeta,

      // Create Button
      addNew,

      navToComponentCreate,
      searchQuery,
      tableColumns,
      fetchPages,
      fetchData,
      isAddNewPageSidebarActive,
      typeForm,
      refListTable,
      refetchData,
      deleteComponent,
      setActive,
    } = useComponent(context)

    return {
      // Sidebar
      loadTable,

      // Filter
      avatarText,
      pageId,
      componentData,
      formatDate,

      // meta pagination
      dataMeta,

      // Create Button
      addNew,

      navToComponentCreate,
      searchQuery,
      tableColumns,
      fetchPages,
      fetchData,
      isAddNewPageSidebarActive,
      typeForm,
      refListTable,
      refetchData,
      deleteComponent,
      setActive,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
