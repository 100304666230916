<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{
            props.typeForm === 'add'
              ? 'Add New Component'
              : props.typeForm === 'edit'
              ? 'Edit Component'
              : 'Detail Component'
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- Full Name -->
          <validation-provider #default="validationContext" name="Nama" rules="required">
            <b-form-group label="Nama" label-for="name">
              <v-select
                id="name"
                v-model="componentData.key"
                :state="getValidationState(validationContext)"
                :options="refComponentOptions"
                :clearable="false"
                placeholder="Nama Component"
                :disabled="!canEdit"
                label="name"
                :reduce="option => option.key"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div v-if="props.typeForm !== 'show'" class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import inject, { ref, watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    Password: () => import('vue-password-strength-meter'),
    DatePicker: () => import('vue2-datepicker'),
    BSidebar,
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPageSidebarActive',
    event: 'update:is-add-new-page-sidebar-active',
  },
  props: {
    isAddNewPageSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeForm: {
      type: String,
      default: 'add',
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      refComponentOptions: [],
    }
  },
  created() {
    store
      .dispatch('cms-pages/fetchRefComponent')
      .then(response => {
        if (response.status === 200) {
          this.refComponentOptions = response.data
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  setup(props, { emit, root }) {
    const blankComponentData = {
      key: null,
    }

    const canEdit = ref(true)

    const componentData = props.data
      ? ref(props.data)
      : ref(JSON.parse(JSON.stringify(blankComponentData)))
    const resetcomponentData = () => {
      componentData.value = JSON.parse(JSON.stringify(blankComponentData))
    }

    watch(
      () => props.isAddNewPageSidebarActive,
      (newValue, prevValue) => {
        if (props.typeForm === 'edit') {
          canEdit.value = true
          componentData.value = props.data
        } else if (props.typeForm === 'show') {
          canEdit.value = false
          componentData.value = props.data
        } else {
          canEdit.value = true
          componentData.value = blankComponentData
        }
      }
    )

    const onSubmit = () => {
      console.log(props.typeForm)
      if (props.typeForm === 'add') {
        store
          .dispatch('cms-pages/postNewComponent', {
            page_id: root.$route.params.page_id,
            component_type: componentData.value.key,
            conten: '',
          })
          .then(response => {
            emit('refetch-data')
            emit('update:is-add-new-page-sidebar-active', false)
          })
      } else {
        const form = {
          key: componentData.value.key,
        }
        console.log(form)
        store.dispatch('cms-pages/editPage', form).then(response => {
          emit('refetch-data')
          emit('update:is-add-new-page-sidebar-active', false)
        })
      }
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetcomponentData)

    return {
      tglLahir: null,
      componentData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      canEdit,
      props,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
