import axios from "@axios";
import crypto from "crypto";
import { Base64 } from "js-base64";
import CryptoJS from "crypto-js";
import Vue from "vue";

const vm = new Vue();

function aesEncrypt(txt) {
  const iv = crypto.randomBytes(8).toString("hex");
  const cipher = CryptoJS.AES.encrypt(
    txt,
    CryptoJS.enc.Utf8.parse("82f2ceed4c503896c8a291e560bd4325"),
    {
      iv: CryptoJS.enc.Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
    }
  );

  const ret = [cipher.toString(), iv];

  return Base64.encode(ret);
}
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPages(ctx, queryParams) {
      console.log("fetch Page");
      return new Promise((resolve, reject) => {
        axios
          .get("/api/auth/pages", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/auth/customer/view/?id=${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePage(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/auth/pages/${id}`)
          .then((response) => {
            resolve(response);
            vm.$bvToast.toast("the Customer has been deleted", {
              title: "Customer Deleted",
            });
          })
          .catch((error) => reject(error));
      });
    },
    addPage(ctx, pageData) {
      pageData.password = aesEncrypt(pageData.password);

      console.log("pageData", pageData);
      return new Promise((resolve, reject) => {
        axios
          .post("/api/auth/pages", pageData)
          .then((response) => {
            if (response.data.errors) {
              vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), {
                title: "Failed to add Page",
              });
              reject(response);
            } else {
              vm.$bvToast.toast("New Page has been added", {
                title: "Page Added",
              });
              resolve(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    editPage(ctx, pageData) {
      // pageData.password = aesEncrypt(pageData.password)

      console.log("pageData", pageData);
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/auth/pages/${pageData.id}`, pageData)
          .then((response) => {
            if (response.data.errors) {
              vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), {
                title: "Failed to update page",
              });
              reject(response);
            } else {
              vm.$bvToast.toast("New Page has been updated", {
                title: "Page Updated",
              });
              resolve(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    updateUser(ctx, { id, UserData }) {
      UserData = UserData.value.data;
      if (typeof UserData.password !== undefined) {
        UserData.password = aesEncrypt(UserData.password);
      }
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/auth/customers/${id}`, UserData)
          .then((response) => {
            if (response.data.errors) {
              vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), {
                title: "Failed to update",
              });
              reject(response);
            } else {
              vm.$bvToast.toast("Update Sucessfully", {
                title: "Customer Added",
              });
              resolve(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    addSeo(ctx, seoData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/auth/seo", seoData)
          .then((response) => {
            if (response.data.errors) {
              vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), {
                title: "Failed to add seo",
              });
              reject(response);
            } else {
              vm.$bvToast.toast("Seo has been added", {
                title: "Seo Added",
              });
              resolve(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    fetchPageById(ctx, pageId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/auth/pages/${pageId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    postEditorComponent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("api/auth/editor-component", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchComponentById(ctx, { id, componentType }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/auth/components/${id}?component_type=${componentType}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRefComponent(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/auth/ref_components")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateComponentContent(ctx, { id, componentType, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `api/auth/components/${id}?component_type=${componentType}`,
            data
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    postNewComponent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("api/auth/components", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteComponent(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`api/auth/components/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    setActiveComponent(ctx, { component_id, is_active, component_type }) {
      return new Promise((resolve, reject) => {
        axios
          .put("api/auth/component/setActive", {
            component_id,
            component_type,
            is_active,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    setPagePublish(ctx, { page_id, is_publish }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`api/auth/page/setPublish/${page_id}`, { publish: is_publish })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
