import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";

export default function useComponent(context) {
  const pageId = ref(0);
  pageId.value = context.root.$route.params.page_id;
  const loadTable = ref(false);

  const typeForm = ref("add");

  const isAddNewPageSidebarActive = ref(false);

  const searchQuery = "";
  const fetchPages = ref([]);
  const tableColumns = [
    {
      key: "component_name",
      sortable: true,
    },
    {
      key: "order",
      sortable: true,
    },
    {
      key: "status",
      sortable: true,
    },
    {
      key: "created_at",
      sortable: true,
    },
    {
      key: "updated_at",
      sortable: true,
    },
    {
      key: "actions",
    },
  ];
  const refListTable = ref(null);

  const dataMeta = ref({
    from: 0,
    of: 0,
    to: 0,
    currentPage: 1,
    total: 0,
    perPage: 100,
    perPageOptions: [10, 25, 50, 100],
    sortBy: "",
    isSortDirDesc: false,
  });

  const navToComponentCreate = (data) => {
    console.log(data);
    context.root.$router.push({
      name: "content-management-create-content",
      params: {
        id: data.componentable_id,
        data,
        component_type: data.componentable_type,
      },
    });
  };
  const addNew = () => {
    isAddNewPageSidebarActive.value = true;
  };
  const deleteComponent = (data) => {
    store
      .dispatch("cms-pages/deleteComponent", data.id)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        refetchData();
      });
  };
  const componentData = ref(null);
  const fetchData = (ctx, callback) => {
    store
      .dispatch("cms-pages/fetchPageById", pageId.value)
      .then((response) => {
        if (response.status === 200) {
          // componentData.value = response.data
          // fetchPages.value = response.data.components
          callback(response.data.components);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        loadTable.value = false;
      });
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  const setActive = (data) => {
    const form = {
      component_id: data.componentable_id,
      component_type: data.componentable_type,
      is_active: data.is_active === 1 ? 0 : 1,
    };
    store
      .dispatch("cms-pages/setActiveComponent", form)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        refetchData();
      });
  };

  return {
    // Sidebar
    loadTable,

    // Filter
    pageId,
    componentData,

    // meta pagination
    dataMeta,

    // Create Button
    addNew,

    navToComponentCreate,
    searchQuery,
    tableColumns,
    fetchPages,
    fetchData,
    isAddNewPageSidebarActive,
    typeForm,
    refListTable,
    refetchData,
    deleteComponent,
    setActive,
  };
}
